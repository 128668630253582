import React from 'react';
import PropTypes from 'prop-types';
import {
  Promo,
  Image,
  MediaIndicator,
  Body,
  Heading,
  LivePulse,
  Meta,
  ItemStatus,
  SectionTag,
  CommentIcon,
  Summary,
} from 'bbc-morph-sport-promos';
import {
  getItemStatusDateTime,
  displayMediaIndicator,
  displaySummary,
  shouldDisplayItemStatus,
  attributesPropTypes,
} from '../helpers/promo';

class StandardPromo extends React.Component {
  getChildContext() {
    const { promoType } = this.props;
    return {
      promoType,
    };
  }

  render() {
    const {
      nowDateTime,
      link,
      heading,
      imageUrl,
      imageAltText,
      articleType,
      layout,
      publishedDateTime,
      scheduledStartTime,
      sectionName,
      sectionUrl,
      isLive,
      commentsCount,
      mediaType,
      onClick,
      summary,
      promoType,
      attributes,
    } = this.props;
    const itemStatusDateTime = getItemStatusDateTime(
      isLive,
      scheduledStartTime,
      publishedDateTime,
      nowDateTime,
    );
    return (
      <Promo attributes={attributes}>
        <Image url={imageUrl} altText={imageAltText}>
          {displayMediaIndicator(mediaType) && <MediaIndicator mediaType={mediaType} />}
        </Image>
        <Body>
          <Heading
            url={link}
            title={heading}
            linkOnClick={onClick}
            summary={displaySummary(promoType, summary) && <Summary text={summary} />}
          >
            {isLive && <LivePulse />}
          </Heading>
          <Meta>
            {shouldDisplayItemStatus(layout, isLive, itemStatusDateTime, articleType) && (
              <ItemStatus itemStatusDateTime={itemStatusDateTime} nowDateTime={nowDateTime} />
            )}
            {sectionName && sectionUrl && (
              <SectionTag sectionName={sectionName} sectionUrl={sectionUrl} />
            )}
            {typeof commentsCount === 'number' && (
              <CommentIcon assetUri={link} commentsCount={commentsCount || null} />
            )}
          </Meta>
        </Body>
      </Promo>
    );
  }
}

StandardPromo.childContextTypes = {
  promoType: PropTypes.string,
};

StandardPromo.displayName = 'SportStandardPromo';

StandardPromo.propTypes = {
  articleType: PropTypes.string,
  nowDateTime: PropTypes.string,
  link: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAltText: PropTypes.string,
  layout: PropTypes.string,
  publishedDateTime: PropTypes.string,
  scheduledStartTime: PropTypes.string,
  sectionName: PropTypes.string,
  sectionUrl: PropTypes.string,
  isLive: PropTypes.bool,
  commentsCount: PropTypes.number,
  mediaType: PropTypes.string,
  onClick: PropTypes.func,
  attributes: PropTypes.objectOf(attributesPropTypes),
  promoType: PropTypes.string,
  summary: PropTypes.string,
};

StandardPromo.defaultProps = {
  articleType: null,
  nowDateTime: null,
  imageUrl: null,
  imageAltText: null,
  layout: null,
  publishedDateTime: null,
  scheduledStartTime: null,
  sectionName: null,
  sectionUrl: null,
  isLive: false,
  commentsCount: null,
  mediaType: null,
  onClick: null,
  attributes: {},
  summary: null,
  promoType: 'stacked',
};

export default StandardPromo;
