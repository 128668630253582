import React from 'react';
import Advert from 'bbc-morph-gnl-advert';
import { IndexTopStoriesLayout } from 'bbc-morph-sport-layouts';
import StandardPromo from '../promos/StandardPromo';
import FeaturedPromo from '../promos/FeaturedPromo';
import renderStandardCluster from '../helpers/standard-cluster';
import { createPromoProps } from '../helpers/promo';

const createInserts = (showAdverts) =>
  showAdverts
    ? {
        3: (
          <Advert
            key="3_advert"
            classes={['qa-sport-index-advert-top-stories']}
            type="mpu"
            groups={[1, 2]}
          />
        ),
        6: (
          <Advert
            key="6_advert"
            classes={['qa-sport-index-advert-top-stories']}
            type="mpu"
            groups={[3]}
          />
        ),
      }
    : {};

const shouldDisplayFeaturedPromo = (item) => item.type === 'Feature' && item.index > 0;

const render = (props) => {
  const { cluster, showAdverts } = props;
  return renderStandardCluster(
    ({ children }) => (
      <IndexTopStoriesLayout inserts={createInserts(showAdverts)}>{children}</IndexTopStoriesLayout>
    ),
    (item) => {
      if (shouldDisplayFeaturedPromo(item)) {
        return <FeaturedPromo key={item.index} {...createPromoProps({ item, ...props })} />;
      }
      return (
        <StandardPromo
          key={item.index}
          promoType={item.index === 0 ? 'lead' : 'stacked'}
          layout="top-stories"
          {...createPromoProps({ item, ...props })}
        />
      );
    },
    cluster,
    { titleHidden: true },
  );
};

export default render;
