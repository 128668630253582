import React from 'react';
import PropTypes from 'prop-types';
import MorphRequire from 'morph-require';
import tournamentLookup from '../helpers/football-tournament-slug-lookup';

const getTrackingAttributes = (feature, isApp, showAdverts) => {
  if (isApp === 'true') {
    return {};
  }

  return {
    'data-bbc-container': 'team-competition-football-scores',
    'data-bbc-title': 'Team Competition Football Scores',
    'data-bbc-source': 'Sport',
    'data-bbc-personalisation': JSON.stringify({
      EDN: showAdverts ? 'gnl' : 'uk',
    }),
    'data-bbc-metadata': JSON.stringify({
      PAR: `team-competition-football-scores::${feature.index + 1}`,
    }),
  };
};

const render = ({ feature, isApp, showAdverts, path }) => {
  const FootballScores = MorphRequire.transitive(
    'bbc-morph-sport-football-scores',
    /* istanbul ignore next */ () => null,
  );

  const trackingAttributes = getTrackingAttributes(feature, isApp, showAdverts);

  return (
    <FootballScores
      key="football-scores"
      tournament={tournamentLookup[path]}
      isApp={isApp}
      wrapperProps={{
        className: 'sp-c-cluster football-scores-wrapper',
        ...trackingAttributes,
      }}
    />
  );
};

render.propTypes = {
  isApp: PropTypes.bool,
  showAdverts: PropTypes.bool,
  feature: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
};

render.defaultProps = {
  isApp: false,
  showAdverts: false,
  feature: {},
  path: null,
};

export default render;
