import React, { PropTypes } from 'react';
import titleOverrides from '../helpers/title-overrides';

function standardisePath(path) {
  return path?.toLowerCase().replace('.app', '');
}

const PageTitle = ({ title, breadcrumbPath, path }) => {
  const h1Text = titleOverrides[path] || title;
  const standardCrumbPath = standardisePath(breadcrumbPath);
  const isHidden = !standardCrumbPath || standardCrumbPath === standardisePath(path);

  return (
    <div className="gel-wrap">
      <h1 className={`page-title gel-trafalgar${isHidden ? ' gs-u-vh' : ''}`} id="page">
        {h1Text}
      </h1>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbPath: PropTypes.string,
  path: PropTypes.string.isRequired,
};

PageTitle.defaultProps = {
  breadcrumbPath: null,
};

export default PageTitle;
