import React from 'react';
import Advert from 'bbc-morph-gnl-advert';

const advertClasses = ['qa-sport-index-advert'];
const renderAdverts = () => [
  {
    location: 'AdvertA',
    element: <Advert classes={advertClasses} type="mpu" groups={[4]} />,
  },
  {
    location: 'AdvertB',
    element: <Advert classes={advertClasses} type="mpu_bottom" groups={[4]} />,
  },
  {
    location: 'AdvertC',
    element: <Advert classes={advertClasses} type="adsense" groups={[4]} />,
  },
  {
    location: 'AdvertD',
    element: <Advert classes={advertClasses} type="mpu_bottom" groups={[1, 2, 3]} />,
  },
  {
    location: 'AdvertE',
    element: <Advert classes={advertClasses} type="adsense" groups={[1, 2, 3]} />,
  },
];

export default renderAdverts;
