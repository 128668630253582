import React from 'react';
import { FourItemClusterLayout } from 'bbc-morph-sport-layouts';
import StandardPromo from '../promos/StandardPromo';
import renderStandardCluster from '../helpers/standard-cluster';
import { createPromoProps } from '../helpers/promo';

const render = (props) =>
  renderStandardCluster(
    FourItemClusterLayout,
    (item) => (
      <StandardPromo
        key={item.index}
        promoType="stacked"
        {...createPromoProps({ item, ...props })}
      />
    ),
    props.cluster,
  );

export default render;
