import React from 'react';
import PropTypes from 'prop-types';
import MorphRequire from 'morph-require';
import footballGroupSlugLookup from '../helpers/football-group-slug-lookup';

const getTrackingAttributes = (feature, isApp, showAdverts) => {
  if (isApp === 'true') {
    return {};
  }

  return {
    'data-bbc-container': 'football-tables',
    'data-bbc-title': 'Football Tables',
    'data-bbc-source': 'Sport',
    'data-bbc-personalisation': JSON.stringify({
      EDN: showAdverts ? 'gnl' : 'uk',
    }),
    'data-bbc-metadata': JSON.stringify({
      PAR: `football-tables::${feature.index + 1}`,
    }),
  };
};

const render = ({ feature, isApp, showAdverts, path, featureToggles }) => {
  const footballTablesToggle =
    featureToggles && featureToggles.find((toggle) => toggle.name === 'football-new-tables');
  let FootballTables;

  if (footballTablesToggle && footballTablesToggle.active) {
    FootballTables = MorphRequire.transitive(
      'bbc-morph-sport-football-table-filtered',
      /* istanbul ignore next */ () => null,
    );
  } else {
    FootballTables = MorphRequire.transitive(
      'bbc-morph-sport-football-table-filter-priority-order',
      /* istanbul ignore next */ () => null,
    );
  }
  const trackingAttributes = getTrackingAttributes(feature, isApp, showAdverts);

  return (
    <div className="sp-c-cluster football-tables-wrapper" {...trackingAttributes}>
      <FootballTables key="football-tables" isApp={isApp} group={footballGroupSlugLookup[path]} />
    </div>
  );
};

render.propTypes = {
  isApp: PropTypes.bool,
  showAdverts: PropTypes.bool,
  feature: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
};

render.defaultProps = {
  isApp: false,
  showAdverts: false,
  feature: {},
  path: '',
};

export default render;
