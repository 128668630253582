import React from 'react';
import PropTypes from 'prop-types';
import { Promo, Link, Heading, MediaIndicator } from 'bbc-morph-sport-promos';
import { attributesPropTypes } from '../helpers/promo';

class OrderedListPromo extends React.Component {
  getChildContext() {
    return {
      promoType: 'orderedList',
    };
  }

  render() {
    const { link, onClick, number, heading, attributes } = this.props;
    return (
      <Promo attributes={attributes}>
        <Link href={link} onClick={onClick}>
          <span className="gs-o-media">
            <span className="sp-c-ordered-list-promo__rank gs-o-media__img gel-canon">
              {number}
            </span>
            <span className="gs-o-media__body">
              <Heading title={heading}>
                <MediaIndicator mediaType="video" />
              </Heading>
            </span>
          </span>
        </Link>
      </Promo>
    );
  }
}

OrderedListPromo.displayName = 'SportOrderedListPromo';

OrderedListPromo.childContextTypes = {
  promoType: PropTypes.string,
};

OrderedListPromo.propTypes = {
  link: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  attributes: PropTypes.objectOf(attributesPropTypes),
};

OrderedListPromo.defaultProps = {
  onClick: null,
  attributes: {},
};

export default OrderedListPromo;
