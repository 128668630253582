import React from 'react';
import { SevenItemClusterLayout } from 'bbc-morph-sport-layouts';
import renderStandardCluster from '../helpers/standard-cluster';
import { createPromoProps } from '../helpers/promo';
import StandardPromo from '../promos/StandardPromo';

const render = (props) =>
  renderStandardCluster(
    SevenItemClusterLayout,
    (item) => (
      <StandardPromo
        key={item.index}
        promoType={item.index === 0 ? 'leadStacked' : 'stacked'}
        {...createPromoProps({ item, ...props })}
      />
    ),
    props.cluster,
  );

export default render;
