const createConfigLikeOnesportHasButBetter = () => ({
  clusters: {
    'Top stories': {
      location: 'A',
      minItems: 1,
      maxItems: 14,
      layout: 'top-stories',
    },
    '4-cluster': {
      location: 'A',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    'Cluster 1': {
      location: 'A',
      minItems: 7,
      maxItems: 7,
      layout: 'seven-item-cluster',
    },
    'Cluster 2': {
      location: 'C',
      minItems: 7,
      maxItems: 7,
      layout: 'seven-item-cluster',
    },
    '5-slice 1': {
      location: 'C',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    '4-slice 1': {
      location: 'C',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    'Most watched': {
      location: 'C',
      minItems: 4,
      maxItems: 4,
      layout: 'ordered-list-cluster',
    },
    '7-slice 1': {
      location: 'E',
      minItems: 7,
      maxItems: 7,
      layout: 'seven-item-cluster',
    },
    '5-slice 2': {
      location: 'E',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    '4-slice 2': {
      location: 'E',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    'Around the UK': {
      location: 'G',
      minItems: 3,
      maxItems: 4,
      layout: 'around-the-uk-cluster',
    },
    '7-slice 2': {
      location: 'F',
      minItems: 7,
      maxItems: 7,
      layout: 'multi-link-cluster',
    },
    '5-slice 3': {
      location: 'G',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    '4-slice 3': {
      location: 'G',
      minItems: 4,
      maxItems: 4,
      layout: 'four-item-cluster',
    },
    '7-slice 3': {
      location: 'G',
      minItems: 7,
      maxItems: 7,
      layout: 'seven-item-cluster',
    },
  },
  features: {
    'football-scores-fixtures': {
      location: 'B',
      layout: 'football-scores-fixtures',
    },
    'football-scores-per-region': {
      location: 'B',
      layout: 'football-scores-fixtures',
    },
    'football-tables': {
      location: 'B',
      layout: 'football-tables',
    },
    'football-table-per-region': {
      location: 'B',
      layout: 'football-tables',
    },
    'football-scores-per-tournament-or-team': {
      location: 'B',
      layout: 'football-scores-per-tournament-or-team',
    },
    'football-table-per-tournament': {
      location: 'B',
      layout: 'football-table-per-tournament',
    },
    'mini-medal-table': {
      location: 'B',
      layout: 'mini-medal-table',
    },
  },
});

export default createConfigLikeOnesportHasButBetter;
