import React from 'react';
import PropTypes from 'prop-types';
import { Promo, Heading, Image, LivePulse, Link } from 'bbc-morph-sport-promos';
import { attributesPropTypes } from '../helpers/promo';

class FeaturedPromo extends React.Component {
  getChildContext() {
    return {
      promoType: 'featured',
    };
  }

  render() {
    const { link, heading, imageUrl, imageAltText, isLive, onClick, attributes } = this.props;
    return (
      <Promo attributes={attributes}>
        <Link href={link} onClick={onClick}>
          <Image url={imageUrl} altText={imageAltText} />
          <Heading title={heading}>{isLive && <LivePulse />}</Heading>
        </Link>
      </Promo>
    );
  }
}

FeaturedPromo.displayName = 'SportFeaturedPromo';

FeaturedPromo.childContextTypes = {
  promoType: PropTypes.string,
};

FeaturedPromo.propTypes = {
  link: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  attributes: PropTypes.objectOf(attributesPropTypes),
  onClick: PropTypes.func,
  isLive: PropTypes.bool,
  imageAltText: PropTypes.string,
  imageUrl: PropTypes.string,
};

FeaturedPromo.defaultProps = {
  attributes: {},
  onClick: null,
  isLive: false,
  imageAltText: null,
  imageUrl: null,
};

export default FeaturedPromo;
