import compareAsc from 'date-fns/compare_asc';
import PropTypes from 'prop-types';
import getEnvironment from './environment';

const dateInFuture = (testDate, nowDate) => compareAsc(testDate, nowDate) === 1;

// ItemStatus display logic:
// || Asset Position || Asset Type || isLive || Display ||
// |   Top Stories   |   LIV       |  true   |    (x)   |
// |   Top Stories   |   LIV       |  false  |    (/)   |
// |   Top Stories   |   Other     |  true   |    (x)   |
// |   Top Stories   |   Other     |  false  |    (/)   |
// |   Other         |   LIV       |  true   |    (x)   |
// |   Other         |   LIV       |  false  |    (/)   |
// |   Other         |   Other     |  true   |    (x)   |
// |   Other         |   Other     |  false  |    (x)   |

const shouldDisplayItemStatus = (layout, isLive, itemStatusDateTime, articleType) => {
  if (!itemStatusDateTime || isLive) {
    return false;
  }

  if (layout === 'top-stories' || articleType === 'LIV') {
    return true;
  }

  return false;
};

// itemStatusDateTime will be based on scheduledStartTime if it exists AND:
//  - the promo is live
//  OR
//  - the scheduledStartTime is in the future
//
// if the above conditions are not met, itemStatus will be based on publishedDateTime where it exists

const getItemStatusDateTime = (isLive, scheduledStartTime, publishedDateTime, nowDateTime) => {
  if (scheduledStartTime && (isLive || dateInFuture(scheduledStartTime, nowDateTime))) {
    return scheduledStartTime;
  }

  return publishedDateTime || null;
};

const displayMediaIndicator = (rawMediaType) => {
  if (!rawMediaType) {
    return false;
  }
  const mediaType = rawMediaType.toLowerCase();
  return mediaType === 'video' || mediaType === 'audio';
};

const displaySummary = (promoType, summary) => summary && promoType.includes('lead');

const getUrl = (path, showAdverts) => {
  const environment = getEnvironment();
  const domain = showAdverts ? 'bbc.com' : 'bbc.co.uk';
  const hostname = environment === 'live' ? `www.${domain}` : `www.${environment}.${domain}`;
  return `https://${hostname}${path}`;
};

const getAttributes = ({ item, section, cluster, isApp, showAdverts }) => {
  if (isApp === 'true') {
    return {};
  }

  const attributes = {
    'data-bbc-container': cluster.title || cluster.clusterTypeTitle,
    'data-bbc-title': item.title,
    'data-bbc-source': section ? section.sectionName : '',
    'data-bbc-personalisation': JSON.stringify({
      EDN: showAdverts ? 'gnl' : 'uk',
    }),
    'data-bbc-metadata': JSON.stringify({
      PAR: `${cluster.clusterTypeTitle}::${cluster.index + 1}`,
      CHD: `card::${item.index + 1}`,
    }),
  };

  // We need to ensure that absolute URLs are used in our tracking data.
  // The code below enables us to use relative URLs for links and return a standardised URL for tracking purposes.
  if (item.link && !item.link.includes('https://')) {
    attributes['data-bbc-result'] = getUrl(item.link, showAdverts);
  }

  return attributes;
};

const getSection = (item) => {
  if (!item.section) {
    return null;
  }

  if (item.section.link.includes('newsround')) {
    return {
      sectionName: 'Newsround',
      sectionUrl: '/newsround/sport',
    };
  }
  if (item.section.link.includes('news')) {
    return {
      sectionName: 'News',
      sectionUrl: '/news',
    };
  }

  return {
    sectionName: item.section.name,
    sectionUrl: item.section.link,
  };
};

const createPromoProps = (props) => {
  const { item, nowDateTime } = props;
  const section = getSection(item);
  return {
    link: item.link,
    heading: item.title,
    imageUrl: item.image ? item.image.url : null,
    imageAltText: item.image ? item.image.description : null,
    publishedDateTime: item.publishedDateTime || null,
    scheduledStartTime: item.scheduledStartTime || null,
    isLive: item.isLive,
    commentsCount: item.commentsCount,
    mediaType: ['Video', 'Audio'].includes(item.type) ? item.type : null,
    articleType: item.articleType,
    summary: item.summary,
    nowDateTime,
    attributes: getAttributes({ section, ...props }),
    ...section,
  };
};

const attributesPropTypes = PropTypes.shape({
  'data-bbc-container': PropTypes.string,
  'data-bbc-title': PropTypes.string,
  'data-bbc-source': PropTypes.string,
  'data-bbc-metadata': PropTypes.objectOf(PropTypes.string),
  'data-bbc-personalisation': PropTypes.objectOf(PropTypes.string),
});

export {
  getItemStatusDateTime,
  displayMediaIndicator,
  displaySummary,
  createPromoProps,
  shouldDisplayItemStatus,
  attributesPropTypes,
};
