import * as UAS from 'bbc-morph-sport-uas-service';

const sendReadsToUAS = ({ path, isApp, uasServiceCalls }) =>
  setTimeout(
    () =>
      UAS.postWithFeatureToggle(uasServiceCalls === 'true', {
        path,
        activityType: 'reads',
        action: 'read',
        resourceId: `urn:bbc:content:assetUri:asset:${encodeURIComponent(path)}`,
        resourceType: 'indexcps',
        isApp: isApp === 'true',
        isLive: false,
      }),
    3000,
  );

export default sendReadsToUAS;
