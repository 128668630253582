const tournamentsLookup = {
  '/sport/football/womens': 'womens',
  '/sport/football/european': 'european',
  '/sport/football/irish': 'irish',
  '/sport/northern-ireland': 'irish',
  '/sport/football/welsh': 'welsh',
  '/sport/wales': 'welsh',
  '/sport/football/scottish': 'scottish',
  '/sport/scotland': 'scottish',
  '/sport/england': 'england',
};

export default tournamentsLookup;
