import React from 'react';
import StaticPrefix from 'morph-static-prefix';

const Footer = () => {
  return (
    <div className="gel-wrap">
      <div className="gel-layout">
        <div className="gel-layout__item gel-2/3@l gel-3/4@xxl">
          <div className="sp-c-cluster sp-c-cluster--no-keyline-bottom">
            <h2 className="gel-double-pica sp-c-cluster__header">Find us here</h2>
            <div className="gel-layout gel-layout--middle gs-u-mb++">
              <div className="gel-layout__item gel-1/2@m gs-u-display-flex">
                <a
                  href="https://twitter.com/BBCSport"
                  className="sp-c-fuh-social sp-c-fuh-social--twitter"
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 44 44"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M29.582 18.724c0-.164 0-.328-.01-.49a7.709 7.709 0 0 0 1.888-1.96 7.538 7.538 0 0 1-2.175.596 3.802 3.802 0 0 0 1.665-2.095 7.596 7.596 0 0 1-2.404.92 3.792 3.792 0 0 0-6.456 3.453 10.75 10.75 0 0 1-7.805-3.957 3.79 3.79 0 0 0 1.173 5.056 3.749 3.749 0 0 1-1.72-.474v.048a3.79 3.79 0 0 0 3.04 3.713 3.773 3.773 0 0 1-1.71.065 3.793 3.793 0 0 0 3.538 2.63A7.61 7.61 0 0 1 13 27.8a10.713 10.713 0 0 0 5.806 1.698c6.966.003 10.776-5.768 10.776-10.773z" />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/BBCSport"
                  className="sp-c-fuh-social sp-c-fuh-social--facebook"
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      d="M43.565,19h-23.13C19.643,19,19,19.642,19,20.435v23.13C19,44.357,19.643,45,20.436,45h12.452V34.932H29.5
                      v-3.924h3.388v-2.894c0-3.358,2.052-5.187,5.047-5.187c1.435,0,2.668,0.107,3.027,0.155v3.509l-2.078,0.001
                      c-1.629,0-1.943,0.774-1.943,1.91v2.505h3.885l-0.506,3.924H36.94V45h6.625C44.358,45,45,44.357,45,43.565v-23.13
                      C45.001,19.643,44.359,19,43.567,19C43.566,19,43.566,19,43.565,19z"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/bbcsport/"
                  className="sp-c-fuh-social sp-c-fuh-social--instagram"
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 44 44"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      d="M8.733 1.577c2.332 0 2.606.01 3.527.052.853.038 1.314.18 1.622.301.41.16.7.347 1.005.652.305.305.496.596.652 1.005.117.308.263.769.301 1.621.042.922.052 1.196.052 3.528s-.01 2.606-.052 3.528c-.038.852-.18 1.313-.301 1.622-.16.409-.347.7-.652 1.005a2.667 2.667 0 0 1-1.005.651c-.308.118-.77.263-1.622.302-.921.041-1.195.052-3.527.052-2.333 0-2.606-.01-3.528-.052-.853-.039-1.313-.18-1.622-.302a2.71 2.71 0 0 1-1.005-.651 2.667 2.667 0 0 1-.651-1.005c-.118-.309-.264-.77-.302-1.622-.041-.922-.052-1.196-.052-3.528s.01-2.606.052-3.528c.038-.852.18-1.313.302-1.621.16-.41.346-.7.651-1.005a2.667 2.667 0 0 1 1.005-.652c.309-.118.77-.263 1.622-.301.922-.045 1.195-.052 3.528-.052zm0-1.574c-2.37 0-2.669.01-3.6.052-.93.042-1.564.191-2.118.406a4.255 4.255 0 0 0-1.546 1.008A4.33 4.33 0 0 0 .461 3.015c-.215.554-.364 1.188-.406 2.12-.041.93-.052 1.227-.052 3.598 0 2.37.01 2.668.052 3.6.042.929.191 1.563.406 2.121.222.575.523 1.06 1.008 1.546a4.33 4.33 0 0 0 1.546 1.008c.554.215 1.188.364 2.12.405.933.042 1.227.052 3.601.052 2.374 0 2.668-.01 3.6-.052.93-.041 1.564-.19 2.121-.405A4.255 4.255 0 0 0 16.003 16a4.33 4.33 0 0 0 1.008-1.546c.215-.554.364-1.189.406-2.12.041-.933.052-1.228.052-3.601 0-2.374-.01-2.669-.052-3.6-.042-.93-.19-1.564-.406-2.122a4.255 4.255 0 0 0-1.008-1.545A4.33 4.33 0 0 0 14.457.457c-.554-.214-1.188-.363-2.12-.405C11.4.014 11.103.003 8.733.003zM8.733 4.249a4.485 4.485 0 0 0 0 8.968 4.487 4.487 0 0 0 4.484-4.484 4.487 4.487 0 0 0-4.484-4.484zm0 7.395a2.91 2.91 0 1 1-.001-5.822 2.91 2.91 0 0 1 0 5.822zM12.347,4.072a1.047,1.047 0 1,0 2.094,0a1.047,1.047 0 1,0 -2.094,0z"
                      transform="translate(13.5 13.5)"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCW6-BQWFA70Dyyc7ZpZ9Xlg"
                  className="sp-c-fuh-social sp-c-fuh-social--youtube"
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 44 44"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M32.761 17.856s-.203-1.5-.848-2.172c-.812-.867-1.72-.879-2.138-.928-2.998-.22-7.477-.22-7.477-.22h-.012s-4.491 0-7.477.22c-.418.05-1.326.061-2.138.928-.645.659-.848 2.172-.848 2.172s-.216 1.77-.216 3.54v1.66c0 1.77.216 3.539.216 3.539s.203 1.501.848 2.172c.812.867 1.887.842 2.353.94 1.708.171 7.274.22 7.274.22s4.49-.012 7.489-.232c.418-.049 1.326-.061 2.138-.928.645-.659.848-2.172.848-2.172s.215-1.77.215-3.54v-1.66c-.012-1.769-.227-3.539-.227-3.539zM20.076 25.07V18.93l5.781 3.076-5.78 3.063z" />
                  </svg>
                </a>
              </div>
              <div className="gel-layout__item gel-1/4@m sp-o-keyline-vertical@m">
                <a href="https://www.bbc.co.uk/sport/35176037" className="sp-o-link-split__anchor">
                  <div className="gs-o-flag gs-u-display-block gs-u-pt+ gs-u-pb++ gs-u-pv@m gs-u-pr">
                    <div className="gs-o-flag__img gs-u-pr+@m">
                      <img
                        src={`${StaticPrefix()}/images/bbc-sport-app-logo.png`}
                        alt=""
                        className="gs-c-fuh-icon"
                      />
                    </div>
                    <div className="gs-o-flag__body">
                      <p className="gel-brevier-bold sp-o-link-split__text">
                        Find out more about our BBC Sport App
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="gel-layout__item gel-1/4@m gel-brevier sp-o-keyline-vertical@m">
                <p className="gs-u-display-inline-block gs-u-display-block@m spacing">
                  <a
                    href="https://www.bbc.co.uk/sport/15561348"
                    className="gel-brevier-bold sp-o-link"
                  >
                    Help &amp; FAQs
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.displayName = 'SportFindUsHere';

export default Footer;
