export default {
  '/sport': 'BBC Sport',
  '/sport/africa': 'Africa',
  '/sport/england': 'Sport England',
  '/sport/northern-ireland': 'Sport Northern Ireland',
  '/sport/northern-ireland/gaelic-games': 'Gaelic Games',
  '/sport/northern-ireland/motorbikes': 'Motorbikes',
  '/sport/scotland': 'Sport Scotland',
  '/sport/wales': 'Sport Wales',
  '/sport/cricket/counties': 'County Cricket',
  '/sport/football/premier-league': 'Premier League Football',
  '/sport/football/championship': 'Championship Football',
  '/sport/football/european-championship': 'European Championship Football',
  '/sport/football/fa-cup': 'FA Cup Football',
  '/sport/football/irish': 'Irish Football',
  '/sport/football/scottish': 'Scottish Football',
  '/sport/football/welsh': 'Welsh Football',
  '/sport/football/world-cup': 'World Cup Football',
  '/sport/get-inspired/activity-guides': 'Activity Guides',
  '/sport/rugby-union/english': 'English Rugby Union',
  '/sport/rugby-union/irish': 'Irish Rugby Union',
  '/sport/rugby-union/welsh': 'Welsh Rugby Union',
  '/sport/rugby-union/scottish': 'Scottish Rugby Union',
};
