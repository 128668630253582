import React from 'react';
import PropTypes from 'prop-types';
import MorphRequire from 'morph-require';

const getTournament = (path) => {
  return path.split('/sport/')[1].split('/')[0];
};

const getTrackingAttributes = (feature, isApp, showAdverts) => {
  if (isApp === 'true') {
    return {};
  }

  return {
    'data-bbc-container': 'medal-table',
    'data-bbc-title': 'Medal Table',
    'data-bbc-source': 'Sport',
    'data-bbc-personalisation': JSON.stringify({
      EDN: showAdverts ? 'gnl' : 'uk',
    }),
    'data-bbc-metadata': JSON.stringify({
      PAR: `medal-table::${feature.index + 1}`,
    }),
  };
};

const render = ({ feature, isApp, showAdverts, path, country }) => {
  const SportMiniMedalByCountry = MorphRequire.transitive(
    'bbc-morph-sport-mini-medals-by-country',
    /* istanbul ignore next */ () => null,
  );
  const trackingAttributes = getTrackingAttributes(feature, isApp, showAdverts);

  return (
    <SportMiniMedalByCountry
      key="sport-mini-medal"
      isApp={isApp}
      tournament={getTournament(path)}
      limit={3}
      location={country}
      url={`/sport/${getTournament(path)}/medals/countries`}
      wrapperProps={{
        className: 'sp-c-cluster sport-mini-medal-by-country-wrapper',
        ...trackingAttributes,
      }}
    />
  );
};

export default render;

render.propTypes = {
  isApp: PropTypes.bool,
  showAdverts: PropTypes.bool,
  feature: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
  country: PropTypes.string,
};

render.defaultProps = {
  isApp: false,
  showAdverts: false,
  feature: {},
  path: null,
  country: '',
};
