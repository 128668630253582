const createFilter = (config) =>
  Object.keys(config.clusters)
    .map((type) => `"${type}"`)
    .join(',');

const createLimits = (config) =>
  Object.keys(config.clusters)
    .map((type) => {
      const min = config.clusters[type].minItems;
      const max = config.clusters[type].maxItems;

      const params = [
        (min || max) && `type: "${type}"`,
        min && `min: ${min}`,
        max && `max: ${max}`,
      ];

      const paramsString = params.filter(Boolean).join(',');

      return paramsString && `{${paramsString}}`;
    })
    .filter(Boolean)
    .join(',');

const createArgs = (args) =>
  Object.keys(args)
    .filter((name) => args[name] !== undefined)
    .map((name) => {
      const value = args[name];
      const formattedValue = typeof value === 'string' ? `"${value}"` : value;
      return `${name}: ${formattedValue}`;
    })
    .join(', ');

const createQuery = (path, isUk, isApp, config, previewGuid) => `{
  content {
    dynamicFeatureToggles {
      active
      name
    }
    index(${createArgs({ path, isUk, isApp, previewGuid })}) {
      clusters(filter: [${createFilter(config)}]) {
        clusterTypeTitle
        items(limits: [${createLimits(config)}]) {
          articleType
          commentsCount
          image {
            description
            url
          }
          isLive
          link
          publishedDateTime
          scheduledStartTime
          section {
            link
            name
          }
          summary
          title
          type
        }
        link
        title
        type
      }
      features {
        name
        props {
          name
          value
        }
      }
      title
    }
  }
  sportNav {
    navigation(${createArgs({ path, isUk, isApp })}) {
      breadcrumbItem {
        link
      }
    }
  }
}`;

export default createQuery;
