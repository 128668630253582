import React from 'react';
import PropTypes from 'prop-types';
import { TwoColumnIndexLayout } from 'bbc-morph-sport-layouts';
import Footer from './Footer';
import renderAdverts from '../helpers/adverts';

const groupRenderedIntoLocations = (rendered) =>
  rendered.reduce((acc, { location, element }) => {
    const key = `location${location}`;
    const existingElements = acc[key] || [];
    const elements = [...existingElements, element];
    return { ...acc, [key]: elements };
  }, {});

// eslint-disable-next-line global-require, import/no-dynamic-require
const getLayout = (name) => require(`../layouts/${name}`).default;

const clusterHasItems = (cluster) => cluster.items.length > 0;

const renderClusters = (clusters, config, isApp, showAdverts, nowDateTime) =>
  clusters.filter(clusterHasItems).map((cluster, index) => {
    const clusterConfig = config.clusters[cluster.type];
    const layout = getLayout(clusterConfig.layout);

    return {
      location: clusterConfig.location,
      element: layout({ cluster: { index, ...cluster }, isApp, showAdverts, nowDateTime }),
    };
  });

const renderFeatures = (features, config, isApp, showAdverts, path, country, featureToggles) =>
  features.map((feature, index) => {
    const featureConfig = config.features[feature.name];
    const layout = getLayout(featureConfig.layout);

    return {
      location: featureConfig.location,
      element: layout({
        feature: { index, ...feature },
        isApp,
        showAdverts,
        path,
        country,
        featureToggles,
      }),
    };
  });

const Layout = ({
  clusters,
  features,
  nowDateTime,
  isApp,
  config,
  showAdverts,
  path,
  country,
  featureToggles,
}) => {
  const renderedClusters = renderClusters(clusters, config, isApp, showAdverts, nowDateTime);
  const renderedFeatures = renderFeatures(
    features,
    config,
    isApp,
    showAdverts,
    path,
    country,
    featureToggles,
  );
  const renderedAdverts = showAdverts ? renderAdverts() : [];

  const locations = groupRenderedIntoLocations([
    ...renderedClusters,
    ...renderedFeatures,
    ...renderedAdverts,
  ]);

  return (
    <div>
      <TwoColumnIndexLayout {...locations} />
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  clusters: PropTypes.string,
  features: PropTypes.string,
  nowDateTime: PropTypes.string,
  isApp: PropTypes.bool,
  config: PropTypes.string,
  showAdverts: PropTypes.bool,
  path: PropTypes.string,
  country: PropTypes.string,
  featureToggles: PropTypes.arrayOf(PropTypes.object),
};

Layout.defaultProps = {
  clusters: null,
  features: null,
  nowDateTime: null,
  isApp: false,
  config: null,
  showAdverts: false,
  path: null,
  country: '',
  featureToggles: [],
};

export default Layout;
