import React from 'react';
import { OrderedListClusterLayout } from 'bbc-morph-sport-layouts';
import OrderedListPromo from '../promos/OrderedListPromo';
import renderStandardCluster from '../helpers/standard-cluster';
import { createPromoProps } from '../helpers/promo';

const render = (props) =>
  renderStandardCluster(
    OrderedListClusterLayout,
    (item) => (
      <OrderedListPromo
        key={item.index}
        number={item.index + 1}
        {...createPromoProps({ item, ...props })}
      />
    ),
    props.cluster,
  );

export default render;
