import lazysizes from 'bbc-morph-lazysizes';
import format from 'date-fns/format';
import { Query } from 'morph-react-graphql';
import PropTypes from 'prop-types';
import React from 'react';
import createQuery from './helpers/query';
import Layout from './components/Layout';
import createConfig from './config';
import sendReadsToUAS from './helpers/send-reads-to-uas';
import PageTitle from './components/PageTitle';

class SportIndex extends React.Component {
  componentDidMount() {
    const { path, isApp, uasServiceCalls } = this.props;
    sendReadsToUAS({ path, isApp, uasServiceCalls });
    lazysizes.load();
  }

  render() {
    const {
      nowDateTime,
      path,
      isApp: isAppString,
      isUk: isUkString,
      previewGuid,
      country,
    } = this.props;
    const isApp = isAppString === 'true';
    const isUk = isUkString === 'true';

    return (
      <Query query={createQuery(path, isUk, isApp, createConfig(), previewGuid)}>
        {({ data }) => (
          <div>
            <PageTitle
              title={data.content.index.title}
              path={path}
              breadcrumbPath={data.sportNav.navigation.breadcrumbItem.link}
            />
            <Layout
              nowDateTime={nowDateTime}
              clusters={data.content.index.clusters}
              features={data.content.index.features}
              isApp={isAppString}
              config={createConfig()}
              showAdverts={!isUk}
              path={path}
              country={country}
              featureToggles={data.content.dynamicFeatureToggles}
            />
          </div>
        )}
      </Query>
    );
  }
}

SportIndex.displayName = 'SportIndex';

SportIndex.propTypes = {
  nowDateTime: PropTypes.string,
  path: PropTypes.string,
  isApp: PropTypes.string,
  isUk: PropTypes.string,
  uasServiceCalls: PropTypes.string,
  previewGuid: PropTypes.string.isRequired,
  country: PropTypes.string,
};

SportIndex.defaultProps = {
  nowDateTime: format(new Date()),
  path: '/sport',
  isApp: 'false',
  isUk: 'true',
  uasServiceCalls: 'true',
  country: '',
};

export default SportIndex;
