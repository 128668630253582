import React from 'react';
import { VerticalListLayout } from 'bbc-morph-sport-layouts';
import StandardPromo from '../promos/StandardPromo';
import renderStandardCluster from '../helpers/standard-cluster';
import { createPromoProps } from '../helpers/promo';

const render = (props) =>
  renderStandardCluster(
    VerticalListLayout,
    (item) => (
      <StandardPromo
        key={item.index}
        promoType={item.index === 0 ? 'leadStackedToInline' : 'stackedToInline'}
        {...createPromoProps({ item, ...props })}
      />
    ),
    props.cluster,
    { className: ['sp-c-cluster--no-keyline-bottom@l', 'gs-u-mt+@l'] },
  );

export default render;
