import React from 'react';
import Cluster from '../components/Cluster';

const renderStandardCluster = (LayoutComponent, promoBuilder, cluster, options = {}) => (
  <Cluster title={cluster.title} link={cluster.link} {...options}>
    <LayoutComponent>
      {cluster.items.map((item, index) => promoBuilder({ index, ...item }))}
    </LayoutComponent>
  </Cluster>
);

export default renderStandardCluster;
