import React from 'react';
import PropTypes from 'prop-types';
import MorphRequire from 'morph-require';
import tournamentLookup from '../helpers/football-tournament-slug-lookup';

const getTrackingAttributes = (feature, isApp, showAdverts) => {
  if (isApp === 'true') {
    return {};
  }

  return {
    'data-bbc-container': 'team-competition-football-tables',
    'data-bbc-title': 'Team Competition Football Tables',
    'data-bbc-source': 'Sport',
    'data-bbc-personalisation': JSON.stringify({
      EDN: showAdverts ? 'gnl' : 'uk',
    }),
    'data-bbc-metadata': JSON.stringify({
      PAR: `team-competition-football-tables::${feature.index + 1}`,
    }),
  };
};

const render = ({ feature, isApp, showAdverts, path }) => {
  const FootballTableCompetition = MorphRequire.transitive(
    'bbc-morph-sport-football-table-competition',
    /* istanbul ignore next */ () => null,
  );
  const trackingAttributes = getTrackingAttributes(feature, isApp, showAdverts);

  return (
    <div
      className="sp-c-cluster football-tables-competition-wrapper gs-u-mt+@l"
      {...trackingAttributes}
    >
      <FootballTableCompetition
        key="football-tables-competition"
        isApp={isApp}
        tournament={tournamentLookup[path]}
      />
    </div>
  );
};

render.propTypes = {
  isApp: PropTypes.bool,
  showAdverts: PropTypes.bool,
  feature: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
};

render.defaultProps = {
  isApp: false,
  showAdverts: false,
  feature: {},
  path: null,
};

export default render;
