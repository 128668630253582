import React from 'react';
import PropTypes from 'prop-types';
import MorphRequire from 'morph-require';
import footballGroupSlugLookup from '../helpers/football-group-slug-lookup';

const getTrackingAttributes = (feature, isApp, showAdverts) => {
  if (isApp === 'true') {
    return {};
  }

  return {
    'data-bbc-container': 'football-scores',
    'data-bbc-title': 'Football Scores',
    'data-bbc-source': 'Sport',
    'data-bbc-personalisation': JSON.stringify({
      EDN: showAdverts ? 'gnl' : 'uk',
    }),
    'data-bbc-metadata': JSON.stringify({
      PAR: `football-scores::${feature.index + 1}`,
    }),
  };
};

const render = ({ feature, isApp, showAdverts, path }) => {
  const FootballScoresFixtures = MorphRequire.transitive(
    'bbc-morph-sport-football-scores-filtered',
    /* istanbul ignore next */ () => null,
  );

  const limitProp = feature.props.filter((prop) => prop.name === 'number')[0];
  const trackingAttributes = getTrackingAttributes(feature, isApp, showAdverts);

  return (
    <div className="sp-c-cluster football-scores-fixtures-wrapper" {...trackingAttributes}>
      <FootballScoresFixtures
        key="football-scores-fixtures"
        abbrAtDesktop="true"
        limit={limitProp ? limitProp.value : '12'}
        isApp={isApp}
        group={footballGroupSlugLookup[path]}
      />
    </div>
  );
};

render.propTypes = {
  isApp: PropTypes.bool,
  showAdverts: PropTypes.bool,
  feature: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
};

render.defaultProps = {
  isApp: false,
  showAdverts: false,
  feature: {},
  path: '',
};

export default render;
